<template>
  <div style="height: 100%">
    <el-card>
      <el-form :model='searchForm' inline label-suffix=': ' @submit.native.prevent>
        <el-form-item :label="$t('lang_name_of_the_resource')" class='mgb-0'>
          <el-input v-model='searchForm.name' @keyup.enter.native='handleSearch' clearable></el-input>
        </el-form-item>
        <el-form-item class='mgb-0'>
          <el-button type='primary' icon='el-icon-search' @click='handleSearch'></el-button>
          <el-button type='success' icon='el-icon-refresh' @click='resetSearch'></el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="hg-100-percent" style="height: calc(100% - 97px); margin-top: 10px">
      <el-table
        ref='tableRef'
        border
        :data='tableData'
        height='calc(100% - 40px)'
        v-loading='tableLoading'
        row-key='id'
        default-expand-all
        :tree-props="{ children: 'children' }"
      >
        <el-table-column :label="$t('lang_serial_number')" align='center' type='index' width='150'></el-table-column>
        <el-table-column :label="$t('lang_name_of_the_resource')" align='left' prop='name' show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('lang_resource_id')" align='left' prop='code' show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('lang_resources_to_address')" align='center' prop='uri' show-overflow-tooltip></el-table-column>
      </el-table>
      <el-pagination
        class="sj-pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-sizes="[10, 15, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import ResourceApi from '@/api/system/ResourceApi';

export default {
  name: 'ResourceList',
  watch: {},
  data() {
    return {
      tableLoading: false, //表格loading动画
      tableData: [],
      pageIndex: 1, //当前页面
      pageSize: 10, // 当前页数
      total: 0, // 总条数
      searchForm: {}
    };
  },
  created() {
    this.getData();
  },
  methods: {
    //获取表格数据源
    getData() {
      this.tableLoading = true;
      let params = {
        page: {
          pageIndex: this.pageIndex,
          pageNo: this.pageNo
        }
      };
      params = Object.assign(this.searchForm, params);
      ResourceApi.page(this.searchForm).then(res => {
        if ((res.code = 1)) {
          this.tableData = res.data.list;
          this.pageIndex = res.data.pageNum;
          this.pageSize = res.data.pageSize;
          this.total = res.data.total;
          this.tableLoading = false;
        }
      });
    },

    // 搜索
    handleSearch() {
      this.getData();
    },

    //清空搜索
    resetSearch() {
      this.searchForm = {};
    },

    // 当前页码修改时
    handleCurrentChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getData();
    },
    // 当前页数修改时
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getData();
    }
  }
};
</script>

<style scoped lang="scss">
</style>
