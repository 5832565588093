import HttpUtil from '@/utils/HttpUtil'

export default {
  //获取列表数据
  list(params) {
    var url = process.env.VUE_APP_SERVER_CODE+"resource/query/list"
    return HttpUtil.post(url, params);
  },

  //获取分页数据
  page(params) {
    var url = process.env.VUE_APP_SERVER_CODE+"resource/query/page"
    return HttpUtil.post(url, params);
  },
}
